import { styled } from '@mui/material';

export const OverlayLoaderContainer = styled('div')(({ theme }) => ({
	position: 'fixed',
	width: '100%',
	height: '100%',
	top: '0',
	right: '0',
	bottom: '0',
	left: '0',
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'center',
	alignItems: 'center',
	background: theme.palette.custom.component.overlay,
}));
