import { parseISO, format } from 'date-fns';

import { getFormattedAmount } from '@shared/utils/number';

import {
	RootBox,
	LabelTypography,
	BalanceTypography,
	NextPaymentTypography,
	DateLabelTypography,
} from './styles';

interface TBalanceBlockProps {
	balanceAmount: number;
	nextPaymentAmount: number;
	nextPaymentDate: string | null;
}

export const BalanceBlock: React.FC<TBalanceBlockProps> = ({
	balanceAmount,
	nextPaymentDate,
	nextPaymentAmount,
}) => (
	<RootBox>
		<LabelTypography>Est. Balance</LabelTypography>

		<BalanceTypography>${getFormattedAmount(balanceAmount)}</BalanceTypography>

		{nextPaymentDate !== null && (
			<>
				<LabelTypography>
					Next Payment:{' '}
					<DateLabelTypography component="span">
						{format(parseISO(nextPaymentDate), 'LLL dd')}
					</DateLabelTypography>
				</LabelTypography>

				<NextPaymentTypography>${getFormattedAmount(nextPaymentAmount)}</NextPaymentTypography>
			</>
		)}
	</RootBox>
);
