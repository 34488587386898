import { makeApi } from '@zodios/core';

import { balanceResponseSchema } from './schemas';

// todo add interceptor in order to transform cents into amount
export const balanceApi = makeApi([
	{
		alias: 'getBalance',
		method: 'get',
		path: '/cabinet/account/balance/',
		response: balanceResponseSchema,
	},
]);
