import { makeApi } from '@zodios/core';

import { logoutResponseSchema } from './schemas';

export const logoutApi = makeApi([
	{
		alias: 'logout',
		method: 'post',
		path: '/cabinet/logout/',
		parameters: [],
		response: logoutResponseSchema,
	},
]);
