import z from 'zod';

export const affiliateDataSchema = z.object({
	aid: z.number(),
	code: z.string(),
	label: z.string(),
	companyName: z.string().nullable(),
	companyAddress: z.string().nullable(),
});

export const userSchema = z.object({
	id: z.number(),
	createdAt: z.string(),
	login: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string().email(),
	affiliateData: affiliateDataSchema,
});

export const userResponseSchema = z.object({
	userData: userSchema,
});

export const userErrorResponseSchema = z.object({
	message: z.string(),
});
