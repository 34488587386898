import { UserLabel, UserLabelSkeleton, userApiHooks } from '@entities/user';

import { UserBox, UserLabelIcon } from './styles';

export const SidebarUserBlock = () => {
	const { data, isSuccess } = userApiHooks.useGetUser();

	const { userData } = data || {};

	const companyName = userData?.affiliateData?.companyName;

	const label = companyName || `${userData?.firstName} ${userData?.lastName}`;

	return (
		<UserBox>
			{isSuccess ? (
				<UserLabel label={label} endElement={<UserLabelIcon />} />
			) : (
				<UserLabelSkeleton />
			)}
		</UserBox>
	);
};
