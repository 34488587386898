import { makeApi } from '@zodios/core';

import { userResponseSchema } from './schemas';

export const userApi = makeApi([
	{
		alias: 'getUser',
		method: 'get',
		path: '/cabinet/user/',
		response: userResponseSchema,
	},
]);
