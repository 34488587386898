import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import { OverlayLoaderContainer } from '@shared/components/overlay-loader/styles';

export const OverlayLoader: React.FC = () => (
	<OverlayLoaderContainer>
		<CircularProgress size="5rem" />
	</OverlayLoaderContainer>
);
