import { type TLoginTokenInfo } from '@shared/api/auth';
import { userRolesSchema } from '@shared/api/auth/schemas';

export type TUserRole = TLoginTokenInfo['roles'][number];

export const userRole = userRolesSchema.parse(
	Object.fromEntries(
		Object.entries(userRolesSchema.shape).map(([key, literal]) => [key, literal.value]),
	),
);
