import { QueryClient } from '@tanstack/react-query';

import { isUnauthorizedError } from './utils/is-unauthorized-error';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry(failureCount, error) {
				return failureCount < 2 && !isUnauthorizedError(error);
			},
		},
	},
});
