import { styled } from '@mui/material';

import { Button } from '@shared/components/button';

export const LogoutButton = styled(Button)(({ theme }) => ({
	flexShrink: 0,
	border: `1px solid ${theme.palette.custom.datepicker.border}`,
	borderRadius: theme.spacing(0.75),
	padding: theme.spacing(0.875, 2),
}));
