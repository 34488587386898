'use client';

import { BalanceBlock, BalanceSkeleton, balanceApiHooks } from '@entities/balance';
import { getAmountFromCents } from '@shared/utils/number';
import { useRole } from '@entities/roles';

export const SidebarBalance: React.FC = () => {
	const { data, isSuccess } = balanceApiHooks.useGetBalance();

	const { isAffiliate } = useRole();

	if (!isAffiliate) {
		return null;
	}

	return isSuccess ? (
		<BalanceBlock
			balanceAmount={Number(getAmountFromCents(data?.balance))}
			nextPaymentAmount={Number(getAmountFromCents(data?.nextPayment.sum))}
			nextPaymentDate={data?.nextPayment.date}
		/>
	) : (
		<BalanceSkeleton />
	);
};
