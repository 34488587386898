'use client';

import { Box, styled, Typography } from '@mui/material';

const MAX_CONTENT_WIDTH = 160; // 8px * 160 = 1280px; 8 is a base scaling factor;
export const TitleTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.h2,
	textAlign: 'left',
}));

export const RootBox = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'stretch',
	minHeight: '100%',
	width: '100%',
}));

export const MainBox = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1, 4, 0),
	flexGrow: 1,
})) as typeof Box;

export const ContentBox = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: theme.spacing(MAX_CONTENT_WIDTH),
	margin: theme.spacing(0, 'auto'),
}));
