'use client';

import { Box, styled, Typography, Skeleton as MuiSkeleton } from '@mui/material';

export const RootBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.custom.light.highest,
	padding: theme.spacing(2),
	borderRadius: theme.spacing(1),
	userSelect: 'none',
}));

export const LabelTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle2,
	color: theme.palette.custom.component.placeholder,
	marginBottom: theme.spacing(0.5),
}));

export const DateLabelTypography = styled(LabelTypography)(({ theme }) => ({
	color: theme.palette.custom.dark.highest,
})) as typeof Typography;

const AmountTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.amountLabel,
}));
export const BalanceTypography = styled(AmountTypography)(({ theme }) => ({
	color: theme.palette.custom.dark.highest,
	marginBottom: theme.spacing(1.5),
}));

export const NextPaymentTypography = styled(AmountTypography)(({ theme }) => ({
	color: theme.palette.secondary.main,
}));

export const Skeleton = styled(MuiSkeleton)(({ theme }) => ({
	width: '100%',
	height: theme.spacing(18),
	borderRadius: theme.spacing(1),
	backgroundColor: theme.palette.custom.component.skeleton,
}));
