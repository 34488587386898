'use client';

import Link from 'next/link';
import {
	Box,
	Stack,
	MenuList as MuiMenuList,
	MenuItem as MuiMenuItem,
	ListSubheader as MuiListSubheader,
	styled,
} from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export const SidebarStack = styled(Stack)(({ theme }) => ({
	alignItems: 'center',
	width: '280px',
	padding: theme.spacing(1, 0),
}));

export const UserBox = styled(Box)(({ theme }) => ({
	width: '100%',
	padding: theme.spacing(1, 2),
}));

export const LogoLink = styled(Link)(({ theme }) => ({
	width: '100%',
	color: theme.palette.custom.dark.highest,
	padding: theme.spacing(2, 4),
}));

export const MenuBox = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	width: '100%',
	padding: theme.spacing(2),
}));

export const MenuList = styled(MuiMenuList)(({ theme }) => ({
	padding: 0,
	marginBottom: theme.spacing(3),
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
	padding: theme.spacing(1.75, 2),
	borderRadius: theme.typography.pxToRem(6),

	'&.MuiMenuItem-root:hover, &.Mui-selected': {
		backgroundColor: '#E4E7ED', // todo Move to the theme
	},
})) as typeof MuiMenuItem; // https://github.com/mui/material-ui/issues/38274

export const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
	...theme.typography.subtitle1,
	marginTop: theme.spacing(1),
	padding: theme.spacing(1.875, 2),
	color: theme.palette.custom.dark.highest,
	backgroundColor: theme.palette.background.default,
}));

export const UserLabelIcon = styled(ArrowForwardIosRoundedIcon)(({ theme }) => ({
	width: theme.spacing(1.5),
	height: theme.spacing(1.5),
}));
