'use client';

import { Logo } from '@shared/components/logo';
import { DASHBOARD_PAGE_PATH } from '@shared/constants';
import { useRole } from '@entities/roles';

import { SidebarLogout } from './sidebar-logout';
import { SidebarBalance } from './sidebar-balance';
import { SidebarUserBlock } from './sidebar-user-block';
import { MenuBox, SidebarStack, LogoLink } from './styles';
import { SidebarMenu } from './sidebar-menu';

export const Sidebar: React.FC = () => {
	const { isAffiliate } = useRole();

	return (
		<SidebarStack>
			<LogoLink href={DASHBOARD_PAGE_PATH}>
				<Logo />
			</LogoLink>

			<MenuBox>
				<SidebarMenu />

				{isAffiliate && <SidebarBalance />}
			</MenuBox>

			{isAffiliate ? <SidebarUserBlock /> : <SidebarLogout />}
		</SidebarStack>
	);
};
