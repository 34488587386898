import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';
import { BASE_URL } from '@shared/api/constants';

import { logoutApi } from './api';

const logoutApiClient = new Zodios(BASE_URL, logoutApi, { axiosInstance });

export const logoutApiHooks = new ZodiosHooks('logout', logoutApiClient);
