'use client';

import { useEffect } from 'react';

import { OverlayLoader } from '@shared/components/overlay-loader';
import { clearApiToken } from '@shared/api/utils/clear-api-token';
import { getAuthStatus } from '@shared/api/utils/get-auth-status';
import { useIsMounted } from '@shared/hooks/use-is-mounted';
import { useNavigate } from '@shared/hooks/use-navigate';
import { getChangePasswordStatus } from '@shared/api/utils/get-change-password-status';
import { UPDATE_PASSWORD_PATH } from '@shared/constants';

import { useAuth } from '../../providers/auth/use-auth';

import { type TAuthGuardProps } from './types';

export const AuthGuard: React.FC<TAuthGuardProps> = ({
	children,
	authorized: expectedAuthorized,
	isPasswordChangeRequired: expectedNeedChangePassword = false,
}) => {
	const navigate = useNavigate();
	const isMounted = useIsMounted();
	const { openSignedInPage, openLoggedOutPage } = useAuth();

	const authorized = getAuthStatus();
	const isPasswordChangeRequired = getChangePasswordStatus();
	const isAuthStateExpected =
		expectedAuthorized === authorized && expectedNeedChangePassword === isPasswordChangeRequired;
	useEffect(() => {
		/**
		 * Don't redirect if current state has expected value
		 */
		if (isAuthStateExpected) {
			return;
		}

		if (authorized) {
			if (!isPasswordChangeRequired) {
				openSignedInPage();
			} else {
				navigate({ to: UPDATE_PASSWORD_PATH });
			}
		} else {
			clearApiToken();
			openLoggedOutPage();
		}
	}, [
		expectedAuthorized,
		openSignedInPage,
		openLoggedOutPage,
		authorized,
		isAuthStateExpected,
		isPasswordChangeRequired,
		navigate,
	]);

	const elementToRender = isAuthStateExpected ? children : <OverlayLoader />;
	return isMounted ? elementToRender : null;
};
