import { getApiToken } from '@shared/api/utils/get-api-token';

export const getChangePasswordStatus = () => {
	const tokenInfo = getApiToken();

	if (typeof tokenInfo !== 'undefined') {
		return tokenInfo.needChangePassword;
	}

	return false;
};
