'use client';

import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { suppressRejection } from '@shared/utils/promise/suppress-rejection';
import { clearApiToken } from '@shared/api/utils/clear-api-token';
import { updateApiToken } from '@shared/api/utils/update-api-token';
import { useNavigate } from '@shared/hooks/use-navigate';
import { AuthContext, type TAuthContext } from '@entities/auth/providers/auth/context';

type TAuthProviderProps = React.PropsWithChildren<{
	signedInUrl: string;
	loggedOutUrl: string;
}>;

export const AuthProvider: React.FC<TAuthProviderProps> = ({
	children,
	signedInUrl,
	loggedOutUrl,
}) => {
	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const authContextValue = useMemo<TAuthContext>(() => {
		const resetQueries = () => suppressRejection(queryClient.resetQueries());

		return {
			openSignedInPage() {
				navigate({ to: signedInUrl });
			},
			openLoggedOutPage(options) {
				navigate({ to: loggedOutUrl, options });
			},
			updateTokenInfo(tokenInfo) {
				updateApiToken(tokenInfo, true);
				resetQueries();
			},
			clearTokenInfo() {
				clearApiToken();
				resetQueries();
			},
		};
	}, [queryClient, navigate, signedInUrl, loggedOutUrl]);

	return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
