import { Skeleton } from '@mui/material';

import { MainBox, TitleTypography, Avatar } from './styles';

export const UserLabelSkeleton: React.FC = () => (
	<MainBox>
		<Skeleton variant="rounded">
			<Avatar />
		</Skeleton>
		<TitleTypography>
			<Skeleton />
		</TitleTypography>
	</MainBox>
);
