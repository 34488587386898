import { useCallback } from 'react';
import { useRouter } from 'next/navigation';

import { type TRouterUrl, type TRouterTransitionOptions } from '@shared/types';
import { canUseDom } from '@shared/utils/dom/can-use-dom';

export type TNavigateOptions = {
	to: TRouterUrl;
	as?: TRouterUrl;
	withReplace?: boolean;
	options?: TRouterTransitionOptions;
};

export const useNavigate = () => {
	const router = useRouter();

	return useCallback(
		({ to, withReplace, options }: TNavigateOptions) => {
			if (!canUseDom()) {
				return;
			}

			if (withReplace) {
				// eslint-disable-next-line @typescript-eslint/no-base-to-string
				router.replace(to.toString(), options);
			} else {
				// eslint-disable-next-line @typescript-eslint/no-base-to-string
				router.push(to.toString(), options);
			}
		},
		[router],
	);
};
