import { useAuth } from '@entities/auth';
import { logoutApiHooks } from '@shared/api/logout';

import { LogoutButton as Button } from './styles';

export const LogoutButton: React.FC = () => {
	const { clearTokenInfo, openLoggedOutPage } = useAuth();
	const { mutate, isLoading } = logoutApiHooks.useLogout();
	return (
		<Button
			disabled={isLoading}
			onClick={() =>
				mutate(undefined, {
					onSettled: () => {
						clearTokenInfo();
						openLoggedOutPage();
					},
				})
			}
		>
			Log Out
		</Button>
	);
};
