import z from 'zod';

export const balanceResponseSchema = z.object({
	balance: z.number(),
	nextPayment: z.object({
		sum: z.number(),
		date: z.string().nullable(), // ISO date
	}),
});

export const balanceErrorResponseSchema = z.object({
	message: z.string(),
});
