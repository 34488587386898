import { forwardRef } from 'react';
import { Link, Typography } from '@mui/material';

import { type TextMaxLineProps } from './types';

const TextMaxLine = forwardRef<HTMLAnchorElement, TextMaxLineProps>(
	({ asLink, line = 2, children, ...props }, ref) => {
		const styles = {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			WebkitLineClamp: line,
			WebkitBoxOrient: 'vertical',
		} as const;

		if (asLink) {
			return (
				<Link color="inherit" ref={ref} sx={{ ...styles }} {...props}>
					{children}
				</Link>
			);
		}

		return (
			<Typography ref={ref} sx={{ ...styles }} {...props}>
				{children}
			</Typography>
		);
	},
);

TextMaxLine.displayName = 'TextMaxLine';

export default TextMaxLine;
