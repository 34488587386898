import Link from 'next/link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { MenuItem } from './styles';

interface TProps {
	label: string;
	path: string;
	icon: React.ReactNode;
	selected: boolean;
}
export const SidebarMenuItem: React.FC<TProps> = ({ label, path, icon, selected }) => (
	<MenuItem component={Link} href={path} selected={selected}>
		<ListItemIcon>{icon}</ListItemIcon>
		<ListItemText>{label}</ListItemText>
	</MenuItem>
);
