import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';
import { BASE_URL } from '@shared/api/constants';

import { balanceApi } from './api';

export const balanceApiClient = new Zodios(BASE_URL, balanceApi, { axiosInstance });

export const balanceApiHooks = new ZodiosHooks('balance', balanceApiClient);
