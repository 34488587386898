import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';
import { BASE_URL } from '@shared/api/constants';

import { userApi } from './api';

export const userApiClient = new Zodios(BASE_URL, userApi, { axiosInstance });
export const userApiHooks = new ZodiosHooks('user', userApiClient);

export * from './types';
