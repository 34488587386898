import { getStorage } from './get-storage';

export const updateStorageState = <S>(key: string, state: S, persistent?: boolean): void => {
	try {
		const serializedState = JSON.stringify(state);

		getStorage(persistent).setItem(key, serializedState);
	} catch (error) {
		// ignore error
	}
};
